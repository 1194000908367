.MenuTabs {
    display: inline-block;
    border-radius: 2px !important;
    padding: 2px;
    color: black;
    box-shadow: 0 3px 6px #ccc !important;
    //box-shadow: 0 3px 6px #5f021f !important;

    cursor: pointer;
    border: 0;
    max-width: 300px;
}

.MenuTabs:hover,
.MenuTabs.active {
    background-color: #FF5900;
    color: white;
}

.CustomCheckBox {
    position: relative;
    color: #484755;
    font-size: 14px;
    //width:120px;
}

.MenuTabs:hover>div,
.MenuTabs.active>div {
    color: white;
}

.CustomCheckBox>.customInput {
    opacity: 0;
    cursor: pointer;
}

.CustomCheckBoxPlain {
    position: relative;
    color: #484755;
    //width:180px;
}


.CustomCheckBoxPlain>.customInput {
    opacity: 0;
    cursor: pointer;
}

.CustomCheckBoxPlain:hover>.customInput~.checkmark {
    background-color: white;
}

.CustomCheckBoxPlain>.customInput:checked~.checkmark {
    background-color: white;
}

.CustomCheckBoxPlain>.customInput:checked~.checkmark-ar {
    background-color: white;
}

.CustomCheckBoxPlain .checkmark:after {
    top: 6px;
    left: 16px;
    width: 13px;
    height: 13px;
}


.CustomCheckBoxPlain>.customInput:checked~.checkmark:after {
    background-image: url("../../../assets/images/Path 659.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
}

.CustomCheckBoxPlain .checkmark-ar:after {
    top: 6px;
    right: 6px;
    width: 13px;
    height: 13px;
}


.checkmark {
    top: 0;
    left: 0;
    height: 20px;
    min-width: 20px !important;
    /* margin-right: 8px;*/
    background-color: white;
    border: 0.001rem solid #DEB887;
    border-radius: 50%;
}

.checkmark-ar {
    top: 0;
    left: 0;
    height: 20px;
    min-width: 20px !important;
    margin-right: -10px;
    background-color: white;
    border: 0.001rem solid #DEB887;
    border-radius: 50%;

}

.CustomCheckBox:hover>.customInput~.checkmark {
    background-color: white;
}

.CustomCheckBox>.customInput:checked~.checkmark {
    background-color: white;
}

.CustomCheckBoxPlain>.customInput:checked~.checkmark {
    background-color: white;
}

.CustomCheckBox>.customInput:checked~.checkmark-ar {
    background-color: white;
}

.CustomCheckBoxPlain>.customInput:checked~.checkmark-ar {
    background-color: white;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkmark-ar:after {
    content: "";
    position: absolute;
    display: none;
}

.CustomCheckBox>.customInput:checked~.checkmark:after {
    background-image: url("../../../assets/images/Path 659.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
}

.CustomCheckBox>.customInput:checked~.checkmark-ar:after {
    background-image: url("../../../assets/images/Path 659.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
}

.CustomCheckBoxPlain>.customInput:checked~.checkmark-ar:after {
    background-image: url("../../../assets/images/Path 659.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
}

.CustomCheckBox .checkmark:after {
    top: 6px;
    left: 16px;
    width: 13px;
    height: 13px;
}

.CustomCheckBox .checkmark-ar:after {
    top: 6px;
    right: 5px;
    width: 13px;
    height: 13px;


}

.CustomDivStyle {
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    text-align: center;
}

@media screen and (max-width:575px) {
    .CustomDivStyle {
        font-size: 12px;
    }

    .min-width-xs-100 {
        min-width: 100% !important;
        margin: 0 !important;
    }

    .min-width-xs-100>div {
        padding: 0 !important;
        justify-content: flex-start !important;
    }

    .min-width-xs-100>div>div {
        width: 50px;
        display: flex;
        justify-content: center;
        padding: 5px;
    }

    .min-width-xs-100>div>div>img {
        height: 30px;
        width: 150px;

    }


}

@media screen and (max-width:1024px) {
    .CustomDivStyle {
        font-size: 12px;
    }

    .min-width-xs-100 {
        min-width: 100% !important;
        margin: 0 !important;
    }

    .min-width-xs-100>div {
        padding: 0 !important;
        justify-content: flex-start !important;
    }

    .min-width-xs-100>div>div {
        width: 50px;
        display: flex;
        justify-content: center;
        padding: 5px;
    }

    .min-width-xs-100>div>div>img {
        height: 30px;
        width: 150px;

    }


}