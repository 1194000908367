
@font-face {
  font-family: 'Droid Arabic Kufi';
  src: local('Droid Arabic Kufi'), url(./assets/fonts/DroidKufi-Regular.ttf) format('truetype');
}
body {
  margin: 0;

 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   font-family: "Droid Arabic Kufi", serif !important;
}
*{
  outline: none !important;
}
.headerLogo{
  width:100px;
  height: 35px;
}
.table td{
  border-color: #ff5900 !important;
}
.table thead th{border:1px solid #000;}
.page-link{
  color:#ff5900 !important;
  border-color: #ff5900 !important;
}
iframe{
  scroll-behavior: smooth !important; 
}
.page-link:hover,.page-item.active>.page-link{
  background-color: #ff5900 !important;
  color:white !important;
}
.select-element.is-invalid,button.select-element.is-invalid {
  border:1px solid #dc3545 !important;
}
input:disabled{
  background-color: white !important;
}
.datePicker-1{
  width:100%;
  height: 35px !important;
  outline:none;
}
.table-responsive-md>tbody{
  cursor: pointer;
}
.modalStyle{
  max-width: 80% !important;
}
.react-datepicker{
  border: 0 !important;
  box-shadow: 3px 3px 6px gray;
}
.react-datepicker__triangle,.react-datepicker__triangle::before{
  border-bottom-color:#ff5900 !important;
}
.react-datepicker>button.react-datepicker__navigation--previous{
  outline: none;
  border-right-color: white;
}
.react-datepicker>button.react-datepicker__navigation--next{
  outline: none;
  border-left-color: white;
}
.react-datepicker__header{
  background-color: #ff5900 !important;
}
.react-datepicker__current-month{
  color: white !important;
}
.react-datepicker__month{
  padding:5px;
}
.react-datepicker__day-names{
  font-weight: 900;
}
.react-datepicker__day-name{
  color:white !important;
}
.react-datepicker__day--selected,.react-datepicker__day--keyboard-selected{
  background-color: #ff5900 !important;
  border-radius: 50% !important;
}
.react-datepicker__day{
  font-weight: 700;
}
.react-datepicker__day:hover{
  outline: none !important;
  background-color: #ff8c4a !important;
  border-radius: 50% !important;
  color:white !important;
}
.react-datepicker__day--outside-month{
  color: gray !important;
}

.modal-header .close>span,.modal-header .close{
  outline: none;
  opacity: 1 !important;
  color:red !important;
}
.position-fixed{
  z-index: 100;
  width: 100%;
  bottom: 0;
  height: 7vh;
}
.loginPage{
  height: 93vh;
}
.passwordHideShow{
  cursor: pointer;
  font-size: 22px;
}
.fa-map-marker{
  font-size:28px;
  color:red;
      position: relative;
    top: -25px;
    left: -7px;
}
.filterModalStyle{
  max-width: 100% !important;
  width: 100% !important;
  position: absolute !important;
  margin:0 !important;
  bottom: 0 !important;
  transform:translateY(0%) !important;
}
.min-width{
  min-width: 300px;
  max-width: 100% !important;
}
.switch-wrapper{margin:20px;}
.total-box{width:100%;background-color: green;text-align:center;height:40px;line-height:2.5em;}
.bxColorBlack{background-color: #000;color:#fff;}
.bxColorYellow {background-color: darkgreen;color:#fff;}
.bxColorRed{background-color: red;color:#fff;}
.MuiAppBar-colorPrimary{background-color: #fff !important;color:#000 !important;font-weight:bold;}
.MuiBox-root-6{padding:0px !important;}
.top-col-left{font-size:14px;padding-right:0px !important;}
.top-col-right{font-size:14px;padding-left:0px !important;}
.fa-map-marker{font-size:21px;}
.top-row-request-info{padding:4px;}
.RequestBtn{text-align: center;}
.bg-green{background-color: green !important}
.bg-red{background-color: red !important}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 2000;
  
}
 
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
 
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
.assignBtns{background-color: #FF5900 !important}
.toast-header{background-color:#FF5900 !important;color: #fff!important;display: block !important}
.text-right{text-align:right;display: flex;}
.top-boxer{font-family: "Droid Arabic Kufi", serif !important;}
.MuiTypography-body1{font-family: "Droid Arabic Kufi", serif !important;}
.app main header h1{    font-family: 'Roboto'; font-size:18px;text-align: right;}
.app .block{margin-bottom :0px !important;}
.switch-wrapper{margin-top:20px;}
.toast{font-weight:bold;font-size:16px;color:#000;}

.reqoverlay {
  position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //background-color: rgba(0, 0, 0, 0.2);
    z-index: 100;
    display:none;

   
  }

.show .reqoverlay{display: block; height:800px;}
.MuiBox-root{padding:0px;}
.close{color: #fff !important;    opacity: 1;}
.accepted-form{}
.app main {padding:8px !important;}
.ask-driver{background-color: #FF5900;}

.ask-driver{
    background-color: #FF5900 !important;
    border-radius: 50px;
    border:0px;
    color:#E9EFFD;
    box-shadow: 2px 2px 3px #ccc;
   padding:3px !important;
}
.ask-driver:hover{
    box-shadow: none;    
}
.link-login{color:#5f021f !important;}
.btn-secondary{    background-color: #5f021f !important;}
.table td, .table th{text-align: center;    padding: 4px !important;}
.table td{border:1px solid #000 !important;}
@media screen and (max-width:576px){
  .modal.show .modal-dialog{
    max-width: 100% !important;
  }
  .btn-end{justify-content: none !important}
  .box { width: 100%;}
  .popover{z-index:3000 !important;}
  .toast{max-width:none;}
}