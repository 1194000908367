.NavLink {
    color: black !important;
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    border-radius: 0;
    margin: 5px;
    padding: 9px 10px 5px 6px;
    box-sizing: border-box;
    transition: all 0.3s;
    display: flex;
    /* justify-content: flex-end; */
}

.NavLink:focus,
.NavLink:hover,
.active {
    background-color: #FF5900;
    font-weight: 600;
    color: #E9EFFD !important;
    border-radius: 50px;
    text-decoration: none;
}

.menuImage {
    display: none;
}

.signupBtn,
.signupBtn:hover,
.signupBtn:focus {
    font-weight: 700;
    background-color: #FF5900 !important;
    height: 33px;
    color: #E9EFFD !important;
    border-radius: 10px;
}

.myAccountListItems {
    font-size: larger;
    cursor: pointer;
    color: black !important;
}

.myAccountListItems:hover {
    background-color: #FF5900;
    color: #E9EFFD !important;
}

.myAccountBtn {
    height: auto;
    align-self: flex-start;
}

.active>.menuImage {
    display: inline-block;
    width: 20px;
    margin-right: 5px;
}

.RequestBtn {
    background-color: #FF5900 !important;
    border-radius: 50px;
    border: 0px;
    color: #E9EFFD;
    box-shadow: 2px 2px 3px #ccc;
    width: 140px;
}

.RequestBtn:hover {
    box-shadow: none;
}

.loginBtn {
    background-color: #FF5900 !important;
    border-radius: 50px;
    border: 0px;
    color: #E9EFFD;
    box-shadow: 2px 2px 3px #ccc;
    width: 160px;
}

.loginBtn:hover {
    box-shadow: none;
}

.joinBtn {
    background-color: #000 !important;
    border-radius: 50px;
    border: 0px;
    color: #E9EFFD;
    box-shadow: 2px 2px 3px #ccc;
    width: 200px;
}

.joinBtn:hover {
    box-shadow: none;
}

.AssignBtn {
    background-color: green !important;
    border-radius: 50px;
    border: 0px;
    color: #E9EFFD;
    box-shadow: 2px 2px 3px #ccc;
    width: 100px;

}

.AssignBtn:hover {
    box-shadow: none;
}

.acceptBtn {
    background-color: green !important;
    border-radius: 50px;
    border: 0px;
    color: #E9EFFD;
    box-shadow: 2px 2px 3px #ccc;
    width: 100px;

}

.acceptBtn:hover {
    box-shadow: none;
}

.denyBtn {
    background-color: red !important;
    border-radius: 50px;
    border: 0px;
    color: #E9EFFD;
    box-shadow: 2px 2px 3px #ccc;
    width: 100px;

}

.denyBtn:hover {
    box-shadow: none;
}

.askBtn {
    background-color: green !important;
    border-radius: 50px;
    border: 0px;
    color: #E9EFFD;
    padding: 3px !important;
    box-shadow: 2px 2px 3px #ccc;
    //width:100px;

}

.askBtn:hover {
    box-shadow: none;
}

.driver-open {
    background-color: #FF5900 !important;
    border-radius: 50px;
    border: 0px;
    color: #E9EFFD;
    padding: 3px !important;
    box-shadow: 2px 2px 3px #ccc;
    //width:100px;

}

.driver-open:hover {
    box-shadow: none;
}

.driver-close {
    background-color: green !important;
    border-radius: 50px;
    border: 0px;
    color: #E9EFFD;
    padding: 3px !important;
    box-shadow: 2px 2px 3px #ccc;
    //width:100px;

}

.driver-open:hover {
    box-shadow: none;
}

.SelectBtns {
    background-color: #FF5900 !important;
    border-radius: 50px;
    border: 0px;
    color: #E9EFFD;
    box-shadow: 2px 2px 3px #ccc;
    //width:180px;
}

.SelectBtns:hover {
    box-shadow: none;
}

.header-link {
    border: 0 !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
}

.request-status-accept {
    color: green;
    font-weight: bold;
}

.request-status-waiting {
    color: orange;
    font-weight: bold;
}

.request-status-finished {
    color: red;
    font-weight: bold;
}

.apply-store-btns {
    margin-top: 10px;
}

.next-row {
    margin-top: 10px;
}

.fa-map-marker {
    top: 0px;
}

.date-wrapper {
    line-height: 3em;
}

.banner {
    width: 100%;
    height: 68px;
    background-color: #b3ecff;
}

.MuiBox-root {
    padding: 0px;
}

.btn-end {
    justify-content: flex-end;
}

.dropdown {
    display: flex;
    align-items: center;
}

.dropdown .btn-secondary {
    font-weight: 700;
    background-color: #ff5900 !important;
    border: none;
    height: 33px;
    color: #e9effd !important;
    //border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 10px;
}

.header {
    background-color: #FFEDE3;
    /*position: -webkit-sticky;
    position: sticky;

    top: 0;*/

}

.nav-toggle-eng {
    display: none;
    color: #000;
}

.nav-toggle-ar {
    display: none;
    color: #000;
}

.top-dashboard {
    display: none
}

.top-company-header {
    display: flex;
    justify-content: space-between;
}

.logo-img-company {
    margin-top: -10px;
}

.header-wrapper-company {
    width: 100%;
}

.modal {
    z-index: 10000 !important;
}

#loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: block;
    opacity: 0.7;
    background-color: #fff;
    z-index: 99;
    text-align: center;
}

#loading-image {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
}

.show-loader {
    display: block;
}

.hide-loader {
    display: none !important;
}

.join-wrapper-text {
    text-align: right;
    margin-right: 66px;
}

@media (min-width:992px) and (max-width:1200px) {
    .NavLink {
        font-size: 14px;
        padding: 5px;
    }
}

/* mobile navbar sidebar under lg breakpoint */
@media (max-width: 992px) {

    .navbar-collapse.collapsing .navbar-nav {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: -35%;
        transition: all 0.2s ease;
    }

    .navbar-collapse.show .navbar-nav-en {
        position: fixed;
        top: 0px;
        bottom: 0;
        right: 0;
        flex-direction: column;
        height: auto;
        width: 75%;
        background-color: azure;
        z-index: 2000;
        transition: left 0.35s ease;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }

    .navbar-collapse.show .navbar-nav-ar {
        position: fixed;
        top: 0px;
        bottom: 0;
        left: 0;
        flex-direction: column;
        height: auto;
        width: 75%;
        background-color: azure;
        z-index: 2000;
        transition: left 0.35s ease;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }

    .navbar-collapse.show .navbar-nav .NavLink {

        color: #000 !important;

    }

    .right-sidepopup {
        position: fixed;
        top: 0px;
        bottom: 0;
        right: 0;
        flex-direction: column;
        height: auto;
        width: 75%;
        background-color: azure;
        justify-content: space-between !important;
        z-index: 2000;
        transition: left 0.35s ease;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        direction: rtl;
    }

    .left-sidepopup {
        position: fixed;
        top: 0px;
        bottom: 0;
        left: 0;
        flex-direction: column;
        height: auto;
        width: 75%;
        background-color: azure;
        z-index: 2000;
        transition: left 0.35s ease;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //background-color: rgba(0, 0, 0, 0.2);
        z-index: 100;
        display: none;
    }

    .popup-side {
        display: flex;
        z-index: 2000;
        position: absolute;
    }

    .btn-toggle-ar {
        margin-right: 100px;
    }

    .btn-toggle-en {
        margin-left: 100px;
    }

    .nav-dir-ar {
        direction: rtl;
    }

    .nav-dir-eng {
        direction: ltr;
    }

}

@media screen and (max-width:576px) {

    .btn-end {
        justify-content: end;
    }

    .show .overlay {
        display: block;
    }

    .close-style {
        display: block;
    }

    .nav-toggle-eng {
        position: absolute;
        z-index: 5000;
        right: 100px;
        display: block
    }

    .nav-toggle-ar {
        position: absolute;
        z-index: 5000;
        left: 100px;
        display: block
    }

    .top-dashboard {
        height: 50px;
        background-color: #fff;
        display: flex;
    }

    .top-dashboard span {
        padding: 12px;
        font-weight: bold;
    }

    .top-dashboard a {
        line-height: 3em;
    }
}